import * as React from "react";
import { Component } from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useContext } from "react";

class Accordions extends Component {
  constructor() {
    super();
  }
  render() {
    const ContextAwareToggle = ({ children, eventKey, callback }) => {
      const currentEventKey = useContext(AccordionContext);
      const isCurrentEventKey = currentEventKey === eventKey;
      let trackLabel = "";
      switch (eventKey) {
        case "0":
          trackLabel = "MSM";
          break;
        case "1":
          trackLabel = "Black Americans";
          break;
        case "2":
          trackLabel = "Hispanic";
          break;
        case "3":
          trackLabel = "Transgender";
          break;
        case "4":
          trackLabel = "Women";
          break;
        case "5":
          trackLabel = "Youth";
          break;
        case "6":
          trackLabel = "Southern US";
          break;
      }
      const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
      );
      return (
        <button
          type="button"
          className={`${isCurrentEventKey ? "active" : ""}`}
          onClick={decoratedOnClick}
          /* data-analytics-event-category="Accordions/Expanders"
          data-analytics-event-label={`${trackLabel} ${isCurrentEventKey ? 'Open' : 'Closed'}`} */
        >
          {children}
        </button>
      );
    };

    return (
      <>
        <Accordion className="body" defaultActiveKey="0">
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="0"
                callback={() => this.props.scrollToSection("gay")}
              >
                <h2 data-anchor="gay" className="title">
                  Gay, Bisexual, and Other Men Who Have Sex with Men
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p>
                  Men who have sex with men (MSM) have been the population most
                  impacted by the epidemic since the beginning.<sup>13</sup>
                  &nbsp;MSM of color are even more disproportionately impacted
                  by HIV, as issues of stigma and discrimination on the basis of
                  sexual orientation intersect with racism and other social
                  determinants of health. If current rates continue, it is
                  estimated that 1 in 6 MSM will be diagnosed with HIV at some
                  point in their lives.<sup>14</sup>
                </p>
                <div className="gray-box">
                  <ul>
                    <li>
                      MSM make up an estimated <strong>2%</strong> of the US
                      population, but accounted for <strong>66%</strong> of new
                      annual HIV infections in 2017<sup>15</sup>
                    </li>
                    <li>
                      As of 2019, <strong>56%</strong> of people living with HIV
                      in the US contracted it through male-to-male sexual
                      transmission<sup>16</sup>
                    </li>
                    <li>
                      As of 2018, <strong>1 in 6</strong> MSM living with HIV
                      was unaware of their status<sup>13</sup>
                    </li>
                    <li>
                      Analysis of HIV diagnoses between <strong>2010</strong>{" "}
                      and <strong>2016</strong> found that new infections among
                      all MSM in the US remained stable, but they increased{" "}
                      <strong>65%</strong> among young Black American MSM aged{" "}
                      <strong>25 to 34</strong> and <strong>68%</strong> among
                      Hispanic/Latino American MSM in that same age group
                      <sup>15</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart">
                      <h3>
                        New HIV <br className="d-block d-md-none" />
                        Diagnoses Among <br className="d-block d-md-none" />
                        MSM in the US by Race/Ethnicity, 2019
                      </h3>
                      <img
                        className="d-none d-md-block"
                        src={"/new-hiv-diagnoses-by-race-2019.svg"}
                        alt="Graph Showing New HIV Diagnoses Among MSM in the US by Race/Ethnicity, 2019"
                      />
                      <img
                        className="d-block d-md-none p-2 w-100"
                        src={"/new-hiv-diagnoses-by-race-2019-mobile.svg"}
                        alt="Graph Showing New HIV Diagnoses Among MSM in the US by Race/Ethnicity, 2019"
                      />
                    </div>
                  </div>
                  <div className="footnote w-75-mobile">
                    Source: CDC. Diagnoses of HIV infection, by race/ethnicity
                    and selected characteristics, 2019.&nbsp;
                    <br className="d-block d-md-none" />
                    <a
                      target="_blank"
                      href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-32.pdf"
                    >
                      <span className="emphasis">
                        HIV Surveillance Supplemental{" "}
                        <br className="d-block d-md-none" />
                        Report
                      </span>
                    </a>
                    . 2019;32.
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="1"
                callback={() => this.props.scrollToSection("black")}
              >
                <h2 data-anchor="black" className="title">
                  Black Americans
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p>
                  Black Americans in this country deal with structural racism
                  and inequality that impacts every aspect of their lives from
                  economic stability to education, housing, food security, and
                  healthcare.<sup>11</sup> Together, this impact may leave
                  individuals at increased risk for HIV and make them less
                  likely to receive the care they need.<sup>4,17</sup> It is not
                  surprising, therefore, that survival after an AIDS diagnosis
                  is lower for Black Americans than for most other racial/ethnic
                  groups or that Black Americans have had the highest
                  age-adjusted death rate due to HIV disease throughout most of
                  the epidemic.<sup>4</sup>
                </p>
                <div className="gray-box">
                  <ul>
                    <li>
                      In <strong>2018</strong>, Black people made up{" "}
                      <strong>13%</strong> of the population but accounted for{" "}
                      <strong>42%</strong> of new HIV diagnoses<sup>17</sup>
                    </li>
                    <li>
                      As of <strong>2018</strong>, an estimated{" "}
                      <strong>40%</strong> of people living with HIV in the US
                      were Black American<sup>17</sup>
                    </li>
                    <li>
                      In <strong>2019</strong>, Black Americans had the highest
                      rate of new HIV diagnoses at <strong>45</strong> diagnoses
                      per <strong>100,000</strong> people, which was more than{" "}
                      <strong>8.5 times</strong> the rate for White Americans
                      <sup>16</sup>
                    </li>
                    <li>
                      Among PrEP medicine users in <strong>2016</strong> for
                      whom race/ethnicity data were available, only about{" "}
                      <strong>11%</strong> were Black American compared to about{" "}
                      <strong>69%</strong> who were White American<sup>8</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, for every <strong>100</strong>{" "}
                      Black Americans living with HIV, <strong>63</strong>{" "}
                      received some care, <strong>48</strong> were retained in
                      care, and <strong>51</strong> were virally suppressed.* In
                      comparison, for every <strong>100</strong> people of all
                      races living with HIV, <strong>65</strong> received some
                      care, <strong>50</strong> were retained in care, and{" "}
                      <strong>56</strong> were virally suppressed<sup>17</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart black">
                      <h3>
                        New HIV <br className="d-block d-md-none" />
                        Diagnoses in the US, 2018
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img p-md-3">
                          <img
                            className="d-none d-md-block"
                            src={"/new-hiv-diagnoses-black-americans-2018.svg"}
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Black Americans, 2018"
                          />
                          <img
                            className="d-block d-md-none p-3 w-100"
                            src={
                              "/new-hiv-diagnoses-black-americans-2018-mobile.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Black Americans, 2018"
                          />
                        </div>
                        <p className="chart-text">
                          Black Americans Accounted&nbsp;For
                          <br className="d-none d-md-block" />
                          <span className="font-red d-inline-block font-heavy">
                            nearly HALF
                          </span>{" "}
                          of new <br className="d-none d-md-block" />
                          HIV diagnoses in{" "}
                          <span className="font-red font-heavy">2018</span>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="footnote w-75-mobile">
                    Source: CDC. Diagnoses of HIV infection, by race/ethnicity
                    and selected characteristics, 2018.&nbsp;
                    <a
                      target="_blank"
                      href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-31.pdf"
                    >
                      <br className="d-block d-md-none" />
                      <span className="emphasis">
                        HIV Surveillance Supplemental{" "}
                        <br className="d-block d-md-none" />
                        Report
                      </span>
                    </a>
                    . 2020;31.
                  </div>
                </div>
                {this.props.ViralSuppressionFootnote}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="2"
                callback={() => this.props.scrollToSection("hispanic")}
              >
                <h2 data-anchor="hispanic" className="title">
                  Hispanic/Latino <br className="d-block d-md-none" />
                  Americans
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <p>
                  Hispanic/Latino American communities deal with
                  disproportionate poverty, lower educational levels, and
                  language barriers that can make it harder for people to access
                  the care and services they need. Hispanic Americans are also
                  more likely to be uninsured than any other community.
                  Moreover, some Hispanic/Latino Americans may not utilize the
                  HIV prevention and care services in their area out of fear of
                  disclosing their immigration status.<sup>18,19</sup>
                </p>
                <div className="gray-box">
                  <ul>
                    <li>
                      Hispanic/Latino Americans made up approximately{" "}
                      <strong>18%</strong> of the US population in{" "}
                      <strong>2017</strong> but accounted for{" "}
                      <strong>27%</strong> of new HIV diagnoses in{" "}
                      <strong>2018</strong>
                      <sup>18,20</sup>
                    </li>
                    <li>
                      In <strong>2019</strong>, Latino/x Americans in the US had
                      a diagnosis rate of about <strong>22</strong> diagnoses
                      per <strong>100,000</strong> people, which was{" "}
                      <strong>4 times</strong> the rate for White Americans
                      <sup>4</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, most new HIV diagnoses in
                      Hispanic/Latino Americans in the US <strong>(85%)</strong>{" "}
                      were among MSM<sup>18</sup>
                    </li>
                    <li>
                      As of <strong>2018</strong>, <strong>1 in 6</strong>{" "}
                      Hispanic/Latino Americans living with HIV in the US was
                      unaware of their status<sup>18</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, for every <strong>100</strong>{" "}
                      Hispanic/Latino Americans living with HIV in the US,{" "}
                      <strong>61</strong> received some care,{" "}
                      <strong>49</strong> were retained in care, and{" "}
                      <strong>53</strong> were virally suppressed.* In
                      comparison, for every <strong>100</strong> people of all
                      races living with HIV, <strong>65</strong> received some
                      care, <strong>50</strong> were retained in care, and{" "}
                      <strong>56</strong> were virally suppressed<sup>18</sup>
                    </li>
                    <li>
                      In one Centers for Disease Control and Prevention (CDC)
                      report from <strong>2017</strong>, only{" "}
                      <strong>21%</strong> of Hispanic/Latino American MSM used
                      PrEP medicine, compared with <strong>31%</strong> of White
                      American MSM<sup>21</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart hispanic">
                      <h3>
                        New hiv Diagnoses Among Hispanic/Latino American Men
                        <br className="d-none d-md-block" />
                        by Transmission Category, 2018
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block"
                            src={
                              "/new-hiv-diagnoses-hispanic-latino-americans-2018.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Hispanic/Latino Americans, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={
                              "/new-hiv-diagnoses-hispanic-latino-americans-2018-mobile.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Hispanic/Latino Americans, 2018"
                          />
                        </div>
                        <p className="chart-text">
                          The Majority of new{" "}
                          <br className="d-none d-md-block" />
                          hiv diagnoses <br className="d-block d-md-none" />
                          among Hispanic/Latino AMERICAN men in 2018 WERE
                          through{" "}
                          <span className="font-red">
                            male-to-male sexual contact
                          </span>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="footnote w-75-mobile">
                    Source: CDC. Diagnoses of HIV infection, by race/ethnicity
                    and selected characteristics, 2018.&nbsp;
                    <br className="d-block d-md-none" />
                    <span className="emphasis">
                      <a
                        target="_blank"
                        href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-31.pdf"
                      >
                        HIV Surveillance Supplemental{" "}
                        <br className="d-block d-md-none" />
                        Report
                      </a>
                    </span>
                    . 2020;31.
                  </div>
                </div>
                {this.props.ViralSuppressionFootnote}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="3"
                callback={() => this.props.scrollToSection("transgender")}
              >
                <h2 data-anchor="transgender" className="title">
                  Transgender People
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <p>
                  Data have not historically captured transgender people, so
                  there is still not a complete understanding of HIV among this
                  group.<sup>22,23</sup> Data across many fields show that
                  transphobia impacts all aspects of life for transmen and
                  transwomen, from housing and employment to health and safety
                  and puts them at increased risk for HIV.<sup>22</sup>{" "}
                  Transgender people also face high rates of violence including
                  sexual assault, which can further increase the HIV risk, and
                  are less likely to receive hospital care, including
                  postexposure prophylaxis, after an assault.<sup>24</sup>
                </p>
                <div className="gray-box">
                  <ul>
                    <li>
                      In <strong>2018</strong>, transgender people accounted for{" "}
                      <strong>2%</strong> of new HIV diagnoses in the US
                      <sup>25</sup>
                    </li>
                    <li>
                      A study of transwomen in <strong>7</strong> major US
                      cities from <strong>2019</strong> to <strong>2020</strong>{" "}
                      found that <strong>42%</strong> of those interviewed were
                      living with HIV<sup>26</sup>
                    </li>
                    <li>
                      According to an analysis of international studies between{" "}
                      <strong>2000</strong> and <strong>2011</strong>,
                      transwomen were <strong>49</strong> times more at risk of
                      living with HIV compared to the general population
                      <sup>27</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, most new HIV diagnoses among
                      transgender people in the US were among Black Americans.
                      Specifically, <strong>40%</strong> of new HIV diagnoses in
                      transgender men and <strong>49%</strong> of new HIV
                      diagnoses in transgender women were among Black Americans
                      <sup>25</sup>
                    </li>
                  </ul>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="4"
                callback={() => this.props.scrollToSection("women")}
              >
                <h2 data-anchor="women" className="title">
                  Women
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <p>
                  In the US, women accounted for approximately 1 in 5 people
                  living with HIV in 2018, yet the narrative of the HIV epidemic
                  often excludes their stories.<sup>28</sup> Biological factors
                  (heterosexual sex is more likely to transmit HIV to a woman
                  from her male partner than vice versa) and social factors
                  (including gender discrimination and barriers to accessing
                  reproductive care) put women at increased risk of HIV.
                  <sup>29,30</sup> In addition, women are more likely to
                  experience intimate partner violence, which also increases
                  their HIV risk. Women of color are further disproportionately
                  impacted by HIV as they experience stigma and discrimination
                  based on both gender and race.<sup>28</sup>
                </p>
                <div className="gray-box">
                  <ul>
                    <li>
                      In <strong>2018</strong>, <strong>19%</strong> of new HIV
                      diagnoses were among women<sup>31</sup>
                    </li>
                    <li>
                      The majority of new HIV diagnoses among women{" "}
                      <strong>(85%)</strong> in <strong>2018</strong> were
                      attributed to heterosexual sex, while another{" "}
                      <strong>15%</strong> were attributed to injection drug use
                      <sup>31</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, for every <strong>100</strong>{" "}
                      women diagnosed with HIV in the US, <strong>76</strong>{" "}
                      received some care, <strong>58</strong> were retained in
                      care, and <strong>63</strong> were virally suppressed.* In
                      comparison, for every <strong>100</strong> people
                      diagnosed with HIV, <strong>76</strong> received some
                      care, <strong>58</strong> were retained in care, and{" "}
                      <strong>65</strong> were virally suppressed.&nbsp;
                      <i>
                        (Note: These rates are among women diagnosed with HIV
                        and do not account for any women living with HIV who may
                        not be aware they have it)
                      </i>
                      <sup>31</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, Black American women made up
                      only <strong>13%</strong> of the US female population and
                      accounted for over half <strong>(58%)</strong> of new HIV
                      diagnoses among women. In comparison, White American women
                      accounted for <strong>21%</strong> and Latinas for{" "}
                      <strong>17%</strong> of new HIV diagnoses among women
                      <sup>28</sup>
                    </li>
                    <li>
                      According to data from <strong>2010 to 2014</strong>, the
                      likelihood of a woman in the US being diagnosed with HIV
                      over the course of her lifetime is significantly higher
                      for Black women <strong>(1 in 54)</strong> and
                      Hispanic/Latina American women <strong>(1 in 256)</strong>{" "}
                      than for White American women <strong>(1 in 941)</strong>
                      <sup>14</sup>
                    </li>
                    <li>
                      There has been some progress in recent years, however,
                      with new HIV diagnoses dropping <strong>10%</strong> among
                      Black American women and <strong>9%</strong> among
                      Hispanic/Latina American women between{" "}
                      <strong>2014</strong> and <strong>2018</strong>
                      <sup>31</sup>
                    </li>
                    <li>
                      According to the CDC, only <strong>7%</strong> of women in
                      the US who would benefit from PrEP medicine were
                      prescribed the prevention regimen in <strong>2018</strong>
                      <sup>32</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart women">
                      <h3>
                        New HIV <br className="d-block d-md-none" />
                        Diagnoses Among Women by Race/Ethnicity in the US, 2018
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block px-4 pt-4 pb-2"
                            src={"/new-hiv-diagnoses-among-women-2018.svg"}
                            alt="Bar Graph Showing New HIV Diagnoses in the US For Women By Race/Ethnicity, 2018"
                          />
                          <img
                            className="d-block d-md-none p-2 w-100"
                            src={
                              "/new-hiv-diagnoses-among-women-2018-mobile.svg"
                            }
                            alt="Bar Graph Showing New HIV Diagnoses in the US For Women By Race/Ethnicity, 2018"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footnote w-75-mobile">
                    Source: CDC. Diagnoses of HIV infection, by race/ethnicity
                    and selected characteristics, 2018.&nbsp;
                    <br className="d-block d-md-none" />
                    <a
                      target="_blank"
                      href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-31.pdf"
                    >
                      <span className="emphasis">
                        HIV Surveillance Supplemental{" "}
                        <br className="d-block d-md-none" />
                        Report
                      </span>
                    </a>
                    . 2020;31.
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="5"
                callback={() => this.props.scrollToSection("young")}
              >
                <h2 data-anchor="young" className="title">
                  Young People
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <p>
                  Young people ages 13 to 24 are among those most at risk for
                  HIV as a result of numerous social, biological, and behavioral
                  factors.<sup>33,34</sup> In 2008, prevalence data suggested
                  that half of new sexually transmitted infections (STIs) occur
                  in young people (aged 15 to 24), and having certain kinds of
                  STIs can increase the risk of acquiring HIV.<sup>35,36</sup>{" "}
                  Young people may also have difficulty gaining access to HIV
                  testing, treatment, and prevention for HIV and other STIs
                  because of lack of transportation, inability to pay, or
                  privacy concerns.<sup>33,37</sup> Young people who are members
                  of other marginalized communities—including young Black
                  American men and young MSM—face increased stigma and higher
                  risk.<sup>4,17</sup>
                </p>
                <div className="gray-box">
                  <ul>
                    <li>
                      <strong>21%</strong> of new HIV diagnoses in{" "}
                      <strong>2018</strong> were among young people ages{" "}
                      <strong>13 to 24</strong>
                      <sup>33</sup>
                    </li>
                    <li>
                      Most new HIV diagnoses among youth <strong>(92%)</strong>{" "}
                      were among young MSM<sup>33</sup>
                    </li>
                    <li>
                      Young people are less likely to know their HIV status. As
                      of <strong>2018</strong>, for every <strong>100</strong>{" "}
                      young people living with HIV in the US, only{" "}
                      <strong>55</strong> knew their status. In comparison, for
                      every <strong>100</strong> people of all ages living with
                      HIV, <strong>86</strong> knew their status<sup>33</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, for every <strong>100</strong>{" "}
                      young people diagnosed with HIV, <strong>79</strong>{" "}
                      received some care, <strong>58</strong> were retained in
                      care, and <strong>60</strong> were virally suppressed.* In
                      comparison, for every <strong>100</strong> people of all
                      ages diagnosed with HIV, <strong>76</strong> received some
                      care, <strong>58</strong> were retained in care, and{" "}
                      <strong>65</strong> were virally suppressed.&nbsp;
                      <i>
                        (Note: These rates are among young people diagnosed with
                        HIV and do not account for any young people living with
                        HIV who may not be aware they have it)
                      </i>
                      <sup>33</sup>
                    </li>
                    <li>
                      According to the CDC, only <strong>11%</strong> of young
                      people aged <strong>16 to 24</strong> in the US who could
                      benefit from PrEP medicine were prescribed the prevention
                      regimen in <strong>2018</strong>
                      <sup>38</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart young">
                      <h3>
                        {/*New HIV <br className="d-block d-md-none" />Diagnoses in the US, 2018*/}
                        New HIV <br className="d-block d-md-none" />
                        Diagnoses Among Young People in{" "}
                        <br className="d-block d-md-none" />
                        the US, 2018
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img p-md-3">
                          <img
                            className="d-none d-md-block p-0"
                            src={
                              "/new-hiv-diagnoses-among-young-people-2018.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US Among Young People, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={
                              "/new-hiv-diagnoses-among-young-people-2018.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US Among Young People, 2018"
                          />
                        </div>
                        <p className="chart-text w-md-44">
                          Young people ages{" "}
                          <span className="font-red">13 to 24</span> accounted
                          for <span className="font-red">21%</span> of new HIV
                          diagnoses in <span className="font-red">2018</span>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="footnote w-75-mobile">
                    Source: CDC. Diagnoses of HIV infection, by race/ethnicity
                    and selected characteristics, 2018.&nbsp;
                    <br className="d-block d-md-none" />
                    <a
                      target="_blank"
                      href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-31.pdf"
                    >
                      <span className="emphasis">
                        HIV Surveillance Supplemental{" "}
                        <br className="d-block d-md-none" />
                        Report
                      </span>
                    </a>
                    . 2020;31.
                  </div>
                </div>
                {this.props.ViralSuppressionFootnote}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="6"
                callback={() => this.props.scrollToSection("south")}
              >
                <h2 data-anchor="south" className="title">
                  The Southern US
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                <p>
                  HIV is more prevalent in the South where the epidemic is
                  largely driven by poverty, racism, and lack of access to
                  healthcare. The South has the highest poverty rate, lowest
                  median household income, and greatest percentage of uninsured
                  people (ie, nearly half of Americans without insurance live in
                  the South) in the country.<sup>39,40</sup> Yet, fewer people
                  in the South are aware they have HIV which means they do not
                  access care or reach viral suppression.* In addition,
                  non-urban areas in the South are bearing the brunt of the
                  nation’s opioid crisis which put residents at additional risk
                  of HIV. The HIV epidemic in the South illustrates the
                  increased risk among those who identify with more than one
                  marginalized group as HIV is more likely among Black Americans
                  in the South and particularly among Black American MSM.
                  <sup>39</sup>
                </p>
                <div className="gray-box">
                  <ul>
                    <li>
                      Data from <strong>2019</strong> showed more than half{" "}
                      <strong>(51%)</strong> of HIV diagnoses occurred in people
                      living in the South even though only <strong>38%</strong>{" "}
                      of the US population lives in this region<sup>39</sup>
                    </li>
                    <li>
                      In <strong>2019</strong>, <strong>8</strong> of the{" "}
                      <strong>10</strong> states and <strong>9</strong> out of{" "}
                      <strong>10</strong> metropolitan areas with the highest
                      rates of new HIV diagnoses were in the South<sup>39</sup>
                    </li>
                    <li>
                      In fact, in <strong>2017</strong>, the South had a greater
                      proportion of new HIV diagnoses than all other regions
                      combined<sup>39</sup>
                    </li>
                    <li>
                      In <strong>2017</strong>, the South accounted for{" "}
                      <strong>47%</strong> of deaths among people with HIV
                      <sup>39</sup>
                    </li>
                    <li>
                      In <strong>2016</strong>, Southerners accounted for only{" "}
                      <strong>27%</strong> of PrEP medicine users<sup>39</sup>
                    </li>
                    <li>
                      Black Americans accounted for <strong>53%</strong> of new
                      HIV diagnoses in the region in <strong>2017</strong>
                      <sup>39</sup>
                    </li>
                    <li>
                      Among Blacks/African Americans in the South,{" "}
                      <strong>6</strong> out of <strong>10</strong> new
                      diagnoses in <strong>2017</strong> were in MSM
                      <sup>39</sup>
                    </li>
                    <li>
                      New HIV diagnoses among Hispanic/Latino American MSM in
                      the South increased <strong>37%</strong> between{" "}
                      <strong>2012</strong> and <strong>2019</strong> while they
                      decreased by <strong>23%</strong> for White MSM in the
                      region during that same time period<sup>16,41</sup>
                    </li>
                    <li>
                      In <strong>2019</strong>, among people living with HIV,
                      the highest percentage of people living with undiagnosed
                      HIV infection was in the Midwest <strong>(14.9%)</strong>{" "}
                      and the South <strong>(14.8%)</strong>, followed by the
                      West <strong>(13.9%)</strong> and Northeast{" "}
                      <strong>(8.6%)</strong>
                      <sup>9</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, <strong>81%</strong> of new HIV
                      diagnoses among men in the South were transmitted through
                      male-to-male sexual contact, <strong>12%</strong> through
                      heterosexual contact, <strong>4%</strong> through
                      injection drug use, and <strong>3%</strong> through
                      injection drug use and male-to-male sexual contact
                      <sup>42</sup>
                    </li>
                    <li>
                      In <strong>2018</strong>, <strong>77%</strong> of new HIV
                      diagnoses among women in the South were transmitted
                      through heterosexual contact and <strong>23%</strong>{" "}
                      through injection drug use<sup>42</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart south">
                      <h3>
                        Rates of People Living with HIV,{" "}
                        <br className="d-block d-md-none" />
                        2018
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block"
                            src={"/rates-of-people-living-with-hiv-2018.svg"}
                            alt="Map of the US Showing Rates of People Living With HIV, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={
                              "/rates-of-people-living-with-hiv-2018-mobile.svg"
                            }
                            alt="Map of the US Showing Rates of People Living With HIV, 2018"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footnote w-75-mobile">
                    Source: AIDSVu. Rates of persons living with HIV, 2018.
                    Local Data: United States. Map data available through{" "}
                    <i>J Med Internet Res</i>, 2020.
                    <br />
                    <a
                      target="_blank"
                      href="https://aidsvu.org/local-data/united-states/"
                    >
                      <span className="underline">
                        https://aidsvu.org/local-data/united-states/
                      </span>
                    </a>
                  </div>
                  <div className="chart-bg">
                    <div className="cont-chart south">
                      <h3>
                        Rates of People <br className="d-block d-md-none" />
                        Newly Diagnosed <br className="d-block d-md-none" />
                        with HIV, 2018
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block"
                            src={
                              "/rates-of-people-newly-diagnosed-with-hiv-2018.svg"
                            }
                            alt="Map of the US Showing Rates of People Newly Diagnosed With HIV, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={
                              "/rates-of-people-newly-diagnosed-with-hiv-2018-mobile.svg"
                            }
                            alt="Map of the US Showing Rates of People Newly Diagnosed With HIV, 2018"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footnote w-75-mobile">
                    Source: AIDSVu. Rates of persons newly diagnosed with HIV,
                    2018. View the Map. Interactive map data available through{" "}
                    <i>J Med Internet Res</i>, 2020.
                    <br className="d-block d-md-none" />
                    <a target="_blank" href="https://map.aidsvu.org/map">
                      <span className="underline">
                        https://map.aidsvu.org/map
                      </span>
                    </a>
                  </div>
                </div>
                {this.props.ViralSuppressionFootnote}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
}

export default Accordions;
